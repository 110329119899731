import { useCallback, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import { format } from 'bloko/common/trl';

import Widget from 'lux/models/employerConstructor/widget.types';
import WidgetTip from 'src/components/EmployerConstructor/WidgetTip';
import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import HeightByRatio from 'src/components/EmployerConstructor/widgets/components/HeightByRatio';
import LoadArea from 'src/components/EmployerConstructor/widgets/components/LoadArea';
import { employerConstructorInvalidPictureSize } from 'src/components/Notifications/EmployerConstructor';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';

import ImageCropPopupContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/ImageCropContainer';
import SideBarContainer, {
    getFileFromEvent,
} from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SideBarContainer';
import SlidesContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SlidesContainer';
import useGalleryActions from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useActions';
import useImageCrop from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useImageCrop';
import useSettings from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useSettings';
import useSlides, {
    SLIDE_TYPE_LOAD_AREA,
} from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useSlides';

const convertToMb = (bytes) => Math.floor(bytes / 1024 / 1024);

const makeLoadAreaHintText = (template, minWidth, minHeight, maxSizeBytes) =>
    format(template, {
        '{0}': minWidth,
        '{1}': minHeight,
        '{2}': convertToMb(maxSizeBytes),
    });

const EditModeContainer = ({ pictures, widgetId, getMovedElementProps, trls }) => {
    const [pictureSettings, widgetSettings] = useSettings();
    const [cropPictureProps, setCropPictureId] = useImageCrop();
    const [
        isUploading,
        isResizing,
        uploadPicture,
        resizePicture,
        resizePictureNoChanges,
        removePicture,
        setPicturesOrder,
        showResizePictureInterface,
    ] = useGalleryActions({
        widgetId,
        setCropPictureId,
    });
    const [isOpenSidebar, toggleOpenSidebar] = useToggleState(false);
    const [slides, activeSlide, setActiveSlide] = useSlides(pictures);
    const isCanAddPicture = pictures.length < widgetSettings.maxCountPictures;
    const { addNotification } = useNotification();

    const editImage = useCallback(
        (...args) => {
            const [pictureId] = args;
            if (pictureId) {
                const picture = pictures.find((pic) => pic.id === pictureId);

                if (
                    picture &&
                    (picture.originalWidth < pictureSettings.minWidth ||
                        picture.originalHeight < pictureSettings.minHeight)
                ) {
                    addNotification(employerConstructorInvalidPictureSize, {
                        props: {
                            minimumWidth: pictureSettings.minWidth,
                            minimumHeight: pictureSettings.minHeight,
                        },
                    });
                    return;
                }
                showResizePictureInterface(...args);
            }
        },
        [addNotification, pictureSettings.minHeight, pictureSettings.minWidth, pictures, showResizePictureInterface]
    );

    const renderLoadArea = useCallback(
        ({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="widget-gallery-load-area">
                <LoadArea
                    isLoading={isUploading}
                    disabled={!isCanAddPicture}
                    inputProps={getInputProps()}
                    hintText={makeLoadAreaHintText(
                        trls[EditModeContainer.trls.loadAreaDescription],
                        pictureSettings.minWidth,
                        pictureSettings.minHeight,
                        pictureSettings.maxSizeBytes
                    )}
                    buttonText={trls[EditModeContainer.trls.uploadButton]}
                />
            </div>
        ),
        [
            isCanAddPicture,
            isUploading,
            pictureSettings.maxSizeBytes,
            pictureSettings.minHeight,
            pictureSettings.minWidth,
            trls,
        ]
    );

    const slidesWithLoadArea = useMemo(
        () => [
            ...slides,
            {
                id: SLIDE_TYPE_LOAD_AREA,
                content: (
                    <Dropzone
                        multiple={false}
                        disableClick={true}
                        accept={pictureSettings.allowedMimeTypes}
                        onDrop={!isCanAddPicture ? null : (event) => uploadPicture(getFileFromEvent(event))}
                    >
                        {renderLoadArea}
                    </Dropzone>
                ),
            },
        ],
        [isCanAddPicture, pictureSettings.allowedMimeTypes, renderLoadArea, slides, uploadPicture]
    );
    const isActiveLoadArea = activeSlide === slidesWithLoadArea.length;

    const slidesContainer = (
        <SlidesContainer slides={slidesWithLoadArea} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
    );

    return (
        <WidgetTip code={Widget.Gallery}>
            <WidgetControls
                getMovedElementProps={getMovedElementProps}
                upload={false}
                edit={!isActiveLoadArea}
                onEdit={toggleOpenSidebar}
                id={widgetId}
                name={trls[EditModeContainer.trls.widgetName]}
                editText={trls[EditModeContainer.trls.addOrUpdate]}
            />
            <div className="widget-gallery">
                {pictures.length > 0 ? (
                    <HeightByRatio ratio={pictureSettings.widthHeightRatio}>{slidesContainer}</HeightByRatio>
                ) : (
                    slidesContainer
                )}
                {isOpenSidebar && !isActiveLoadArea && (
                    <SideBarContainer
                        pictures={pictures}
                        canAddPicture={isCanAddPicture}
                        editImage={editImage}
                        setOrder={setPicturesOrder}
                        uploadImage={uploadPicture}
                        removePicture={removePicture}
                        isUploading={isUploading}
                        pictureSettings={pictureSettings}
                    />
                )}
            </div>
            <ImageCropPopupContainer
                {...cropPictureProps}
                noChanges={resizePictureNoChanges}
                onSave={resizePicture}
                resizeInProgress={isResizing}
            />
        </WidgetTip>
    );
};

EditModeContainer.trls = {
    loadAreaDescription: 'employer.constructor.widget.image.loadareadescription',
    uploadButton: 'employer.constructor.widget.image.uploadButton',
    widgetName: 'employer.constructor.widget.type.gallery',
    addOrUpdate: 'employer.constructor.widget.gallery.addOrUpdate',
};

EditModeContainer.propTypes = {
    trls: PropTypes.object,
    pictures: PropTypes.array,
    widgetId: PropTypes.number,
    getMovedElementProps: PropTypes.func,
};

export default translation(EditModeContainer);
