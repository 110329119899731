import {
    employerConstructorModifyImage,
    PICTURE_RESIZE_MAX_TRIES,
    Status,
    switchImagesActionWrapper,
} from 'lux/models/employerConstructor';
import {
    EMPLOYER_CONSTRUCTOR_IMAGE_RESIZED,
    EMPLOYER_CONSTRUCTOR_RESPONSE_TIMEOUT,
} from 'lux/models/employerConstructor/constants';
import defaultError from 'src/components/Notifications/DefaultError';
import {
    employerConstructorImageResized,
    employerConstructorResponseTimeOut,
} from 'src/components/Notifications/EmployerConstructor';
import fetcher from 'src/utils/fetcher';

const notificationsMap = {
    [EMPLOYER_CONSTRUCTOR_RESPONSE_TIMEOUT]: employerConstructorResponseTimeOut,
    [EMPLOYER_CONSTRUCTOR_IMAGE_RESIZED]: employerConstructorImageResized,
};

export const CHECK_TIMEOUT_MS = 200;

const checkResizeStatus =
    ({ pictureId, widgetId }, { fromUpload, showErrorAction }, addNotification, triesLeft = PICTURE_RESIZE_MAX_TRIES) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            const failHandle = (errorType) => {
                dispatch(
                    switchImagesActionWrapper({
                        payload: {
                            resizeStatus: Status.Fail,
                        },
                        widgetId,
                    })
                );
                if (!errorType) {
                    addNotification(defaultError);
                } else if (showErrorAction) {
                    dispatch(showErrorAction(errorType));
                } else {
                    addNotification(notificationsMap[errorType]);
                }
                reject();
            };

            fetcher.get(`/employer/constructor/check_resize_status?pictureId=${pictureId}`).then(
                (data) => {
                    if (data.status === Status.Success) {
                        dispatch([
                            employerConstructorModifyImage({
                                path: data.path,
                                pictureId,
                            }),
                            switchImagesActionWrapper({
                                payload: {
                                    resizeStatus: Status.Success,
                                    ...(!fromUpload && {
                                        pictureId,
                                    }),
                                },
                                widgetId,
                            }),
                        ]);
                        if (!fromUpload) {
                            addNotification(employerConstructorImageResized);
                        }
                        resolve();
                    } else if (data.status === Status.Fail) {
                        failHandle();
                    } else if (!triesLeft) {
                        failHandle(EMPLOYER_CONSTRUCTOR_RESPONSE_TIMEOUT);
                    } else {
                        setTimeout(() => {
                            dispatch(
                                checkResizeStatus(
                                    { pictureId, widgetId },
                                    { fromUpload, showErrorAction },
                                    addNotification,
                                    triesLeft - 1
                                )
                            )
                                .then(() => {
                                    resolve();
                                })
                                .catch(() => {
                                    reject();
                                });
                        }, CHECK_TIMEOUT_MS);
                    }
                },
                () => {
                    failHandle();
                }
            );
        });
    };

export default checkResizeStatus;
