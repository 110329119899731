import { FORBIDDEN } from 'http-status-codes';

import { employerConstructorAddImage, Status, switchImagesActionWrapper } from 'lux/models/employerConstructor';
import {
    INVALID_SELECTION_LOCATION_ERROR,
    INVALID_SELECTION_RATIO_ERROR,
    INVALID_SELECTION_SIZE_ERROR,
} from 'lux/models/employerConstructor/constants';
import defaultError from 'src/components/Notifications/DefaultError';
import { employerConstructorEditForbiddenError } from 'src/components/Notifications/EmployerConstructor';
import fetcher from 'src/utils/fetcher';

import checkResizeStatus, { CHECK_TIMEOUT_MS } from 'src/components/EmployerConstructor/checkResizeStatus';

const CONSTRUCTOR_EDIT_NOT_PERMITTED = 'CONSTRUCTOR_EDIT_NOT_PERMITTED';

const getErrorType = ({ response }) =>
    response?.status === FORBIDDEN ? CONSTRUCTOR_EDIT_NOT_PERMITTED : response?.data?.error?.[0]?.key;

const resizeImage =
    ({ resizeParams, widgetId }, { showErrorAction } = {}, addNotification) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                switchImagesActionWrapper({
                    payload: {
                        resizeStatus: Status.Fetching,
                    },
                    widgetId,
                })
            );
            const { pictureId, x, y, width, height, originalPath, ...rest } = resizeParams;

            return fetcher
                .postFormData('/employer/constructor/resize_image', {
                    pictureId,
                    selectionLeft: x,
                    selectionTop: y,
                    selectionWidth: width,
                    selectionHeight: height,
                    ...rest,
                })
                .then(
                    ({ data }) => {
                        dispatch([
                            employerConstructorAddImage({ ...data, originalPath }),
                            switchImagesActionWrapper({
                                payload: {
                                    resizeStatus: Status.Fetching,
                                },
                                widgetId,
                            }),
                        ]);
                        setTimeout(() => {
                            dispatch(
                                checkResizeStatus(
                                    { pictureId: data.pictureId, widgetId },
                                    { showErrorAction },
                                    addNotification
                                )
                            )
                                .then(() => resolve(data))
                                .catch(() => reject());
                        }, CHECK_TIMEOUT_MS);
                    },
                    (error) => {
                        dispatch(switchImagesActionWrapper({ payload: { resizeStatus: Status.Fail }, widgetId }));
                        // TODO: почему не кропнулась?
                        const errorType = getErrorType(error);
                        switch (errorType) {
                            case CONSTRUCTOR_EDIT_NOT_PERMITTED:
                                addNotification(employerConstructorEditForbiddenError);
                                break;
                            case INVALID_SELECTION_LOCATION_ERROR:
                            case INVALID_SELECTION_SIZE_ERROR:
                            case INVALID_SELECTION_RATIO_ERROR:
                                dispatch(showErrorAction(errorType));
                                break;
                            default:
                                addNotification(defaultError);
                        }
                        reject();
                    }
                );
        });
    };

export default resizeImage;
