import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setModalError as setModalErrorAction } from 'lux/models/employerConstructor';
import ImageCropPopup from 'src/components/ImageCropPopup';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const EMPTY_ERROR_STATE = null;

const ImageCropPopupContainer = ({
    visible,
    onSave,
    onClose,
    noChanges,
    pictureId,
    typedPictureId,
    trls,
    resizeInProgress,
}) => {
    const dispatch = useDispatch();
    const error = useSelector(({ employerConstructor }) => employerConstructor.modalError);
    const pictureSettings = useSelector(
        ({ employerConstructorSettings }) => employerConstructorSettings.pictureSettings.GALLERY_WIDGET
    );
    const pictureInfo = useSelector(({ employerConstructor }) =>
        employerConstructor.images.find((image) => image.pictureId === pictureId)
    );

    const { originalWidth, originalHeight, originalPath } = pictureInfo || {};

    const enhancedOnSave = useCallback(
        async (cropParams) => {
            if (cropParams.noChanges) {
                noChanges(pictureId, typedPictureId);
                onClose();
                return;
            }

            dispatch(setModalErrorAction(EMPTY_ERROR_STATE));
            await onSave(pictureId, typedPictureId, {
                ...cropParams.absoluteSizes,
                originalWidth,
                originalHeight,
                originalPath,
            });
            onClose();
        },
        [dispatch, onSave, pictureId, typedPictureId, originalWidth, originalHeight, originalPath, onClose, noChanges]
    );

    const imageCropSettings = useMemo(
        () => ({
            src: pictureInfo?.originalPath,
            stateX: pictureInfo?.selectionLeft,
            stateY: pictureInfo?.selectionTop,
            stateWidth: pictureInfo?.selectionWidth,
            stateHeight: pictureInfo?.selectionHeight,
            originalWidth: pictureInfo?.originalWidth,
            originalHeight: pictureInfo?.originalHeight,
            ratio: pictureSettings.widthHeightRatio,
            minimumWidth: pictureSettings.minWidth,
            minimumHeight: pictureSettings.minHeight,
        }),
        [pictureInfo, pictureSettings]
    );

    return (
        <ImageCropPopup
            error={error}
            onClose={onClose}
            onDragStop={() => error && dispatch(setModalErrorAction(EMPTY_ERROR_STATE))}
            onSave={enhancedOnSave}
            visible={visible}
            resizeInProgress={resizeInProgress}
            imageCropSettings={imageCropSettings}
            title={trls[ImageCropPopupContainer.trls.title]}
            description={trls[ImageCropPopupContainer.trls.description]}
            save={trls[ImageCropPopupContainer.trls.save]}
            cancel={trls[ImageCropPopupContainer.trls.cancel]}
        />
    );
};

ImageCropPopupContainer.propTypes = {
    visible: PropTypes.bool,
    resizeInProgress: PropTypes.bool,
    pictureId: PropTypes.number,
    typedPictureId: PropTypes.number,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    noChanges: PropTypes.func.isRequired,
    trls: PropTypes.object.isRequired,
};

ImageCropPopupContainer.trls = {
    title: 'employer.constructor.widget.image.cropimage.title',
    description: 'employer.constructor.widget.image.cropimage.description',
    save: 'employer.constructor.cropimage.button.save',
    cancel: 'employer.constructor.cropimage.button.cancel',
};

export default translation(ImageCropPopupContainer);
